@font-face {
    font-family: Enshaedn-Regular;
    src: local('Enshaedn-Regular'), url(./fonts/Enshaedn-Regular.ttf) format('truetype');
}

html, body, h1, h2, h3, h4, div, p, span, a, ol, ul, li, header, footer, main, section, article, aside, img, option, select, form, table, textarea, title {
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    background-color: black;
    color: ivory;
}

header {
    padding: 0;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    background-color: rgb(30, 30, 30);
}

.header-logo {
    padding-top: 1%;
    font-size: 7em;
    font-family: Enshaedn-Regular, Arial, Helvetica, sans-serif;
}

main {
    padding: 2% 25%;
    margin: 0 auto
}

.blog-post {
    text-align: left;
    border-left: solid 2px rgb(100, 100, 100);
    border-right: solid 2px rgb(100, 100, 100);
    padding: 0 2%;
}

.blog-header {
    padding-bottom: 1%;
    border-bottom: solid 2px rgb(100, 100, 100);
}

.blog-header span {
    font-size: .75em;
}

.blog-content {
    padding-top: 2%;
    text-indent: 4%;
}

.blog-content p {
    margin-bottom: 1%;
}

.blog-footer {
    text-align: right;
    font-family: Enshaedn-Regular;
}